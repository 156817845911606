document.addEventListener('turbolinks:load', function() {
  if ($("#parentFolder").length) {
    let folderID = $("#parentFolder").data("id")


    document.getElementById("edit-delete").onclick = function() {
      let folders = [];
      let files = [];
      $('.folder-object:checked').each(function(i, obj) {
        folders.push(obj.getAttribute("data-id"))
      });
      $('.file-object:checked').each(function(i, obj) {
        files.push(obj.getAttribute("data-id"))
      });
      $.ajax('/admin/folders/' + folderID, {
        method: "DELETE",
        dataType: 'script',
        data: {
          folders: folders,
          files: files,
          authenticity_token: $('[name="csrf-token"]')[0].content,
        }
      });
    };

    document.getElementById("edit-rename").onclick = function() {
      let folders = [];
      let files = [];
      $('.folder-object:checked').each(function(i, obj) {
        folders.push(obj.getAttribute("data-id"))
      });
      $('.file-object:checked').each(function(i, obj) {
        files.push(obj.getAttribute("data-id"))
      });
      $.ajax('/admin/folders/' + folderID + '/rename', {
        method: "POST",
        dataType: 'script',
        data: {
          folders: folders,
          files: files,
          authenticity_token: $('[name="csrf-token"]')[0].content,
        }
      });
    };

    document.getElementById("edit-move").onclick = function() {
      let folders = [];
      let files = [];
      $('.folder-object:checked').each(function(i, obj) {
        folders.push(obj.getAttribute("data-id"))
      });
      $('.file-object:checked').each(function(i, obj) {
        files.push(obj.getAttribute("data-id"))
      });
      $.ajax('/admin/folders/' + folderID + '/move', {
        method: "POST",
        dataType: 'script',
        data: {
          folders: folders,
          files: files,
          authenticity_token: $('[name="csrf-token"]')[0].content,
        }
      });
    };

    var moveFilesFolders = function() {
      let moveToId = $(this).data("id");
      let folders = [];
      let files = [];
      $('.folder-object:checked').each(function() {
        folders.push($(this).data("id"))
      });
      $('.file-object:checked').each(function() {
        files.push($(this).data("id"))
      });
      folders.forEach(function(id){
        $.ajax('/admin/folders/' + folderID + '/sub-folder/' + id + '/move', {
          method: "PATCH",
          dataType: 'script',
          data: {
            new_folder: moveToId,
            authenticity_token: $('[name="csrf-token"]')[0].content,
          }
        });
      });
      files.forEach(function(id){
        $.ajax('/admin/folders/' + folderID + '/files/' + id + '/move', {
          method: "PATCH",
          dataType: 'script',
          data: {
            new_folder: moveToId,
            authenticity_token: $('[name="csrf-token"]')[0].content,
          }
        });
      });
      $(document).unbind('click', moveFilesFolders);
    };

    $(document).on({
      click: moveFilesFolders         
    }, '.move-to-folder');

    $(document).on('click', '[data-expand-folder]', function() {
      let expandFolder = $(this).data('expand-folder')
      let movingFolders = $(this).data('moving-folders')
      $.ajax(`/admin/folders/${folderID}/expand`, {
        method: "GET",
        dataType: 'script',
        data: {
          expand_folder: expandFolder,
          moving_folders: movingFolders,
          authenticity_token: $('[name="csrf-token"]')[0].content,
        }
      });
    });
  };
});